import { default as FacebookIcon } from './FacebookIcon'
import { default as InstagramIcon } from './InstagramIcon'
import { default as XIcon } from './XIcon'
import { default as LinkedinIcon } from './LinkedinIcon'
import { SVGProps } from 'react'

const svgIcons = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  x: XIcon,
  linkedin: LinkedinIcon
}

export const SvgIcon = ({ name, ...props }: SVGProps<SVGSVGElement> & { name: keyof typeof svgIcons }) => {

  const IconComponent = svgIcons[name];

  return <IconComponent {...props} />
}