import styled, { AnyStyledComponent } from 'styled-components'
import { useSelector } from 'react-redux'

import ContentContainer from 'src/components/ContentContainer'
import LogoIcon from 'src/assets/icons/logo.svg?react'
import { RootState } from 'src/utilities/store'
import RestaurantButton from './RestaurantButton'
import IconButton from 'src/components/IconButton'
import { selectHasCustomPages } from 'src/models/website'
import { useHeaderTitle } from 'src/utilities/hooks'
import { QrTitleDisplayType } from 'src/types/api'

export const HEADER_HEIGHT = 72 as const

interface HeaderProps {
  brandLogoUrl?: string
  bgImgVisible?: boolean
  onRestaurantClick?: () => void
  onMenuClick?: () => void
}

interface HeaderContainerProps {
  bgImgVisible?: boolean
}

const HeaderContainer = styled.div<HeaderContainerProps>`
  height: ${HEADER_HEIGHT}px;
  background-color: ${({ theme, bgImgVisible }) =>
    bgImgVisible ? `${theme.colors.baseItemColor}80` : theme.colors.baseItemColor};
  display: grid;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  transition: 0.1s background-color;
`

const ContentContainerStyled = styled(ContentContainer)`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
`

const LeftContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  column-gap: 3.5rem;
`

const RightContentContainer = styled.div``

const LogoIconStyled = styled(LogoIcon as unknown as AnyStyledComponent)`
  height: 2.75rem;
  width: auto;
  color: ${({ theme }) => theme.colors.baseItemTextColor};
`

const BrandLogo = styled.img.attrs({
  alt: 'brand-logo',
})`
  height: 2.75rem;
  width: auto;
`

const Header = ({ bgImgVisible, brandLogoUrl, onMenuClick, onRestaurantClick }: HeaderProps) => {
  const group = useSelector((state: RootState) => state.group.group)
  const titleDisplayType = useSelector((state: RootState) => state.profile.user?.qrTitleDisplayType)

  const hasCustomPages = useSelector(selectHasCustomPages)

  const headerTitle = useHeaderTitle()

  return (
    <HeaderContainer bgImgVisible={bgImgVisible}>
      <ContentContainerStyled>
        <LeftContentContainer>
          {brandLogoUrl ? <BrandLogo src={brandLogoUrl} /> : <LogoIconStyled />}

          {titleDisplayType !== QrTitleDisplayType.None && <RestaurantButton disabled={!group} onClick={onRestaurantClick}>
            {headerTitle}
          </RestaurantButton>}
        </LeftContentContainer>

        <RightContentContainer>
          {hasCustomPages && <IconButton name="menu" onClick={onMenuClick} />}
        </RightContentContainer>
      </ContentContainerStyled>
    </HeaderContainer>
  )
}

export default Header
