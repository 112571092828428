import { SVGProps } from "react"

const XIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={39}
    height={40}
    fill="none"
    viewBox="0 0 38 40"
    {...props}
  >
    <path
      fill={props.fill || "#F9BA42"}
      d="M29.78 3.173h5.482l-11.976 14.04 14.089 19.103H26.343l-8.64-11.586-9.887 11.586H2.331L15.14 21.3 1.625 3.173h11.312l7.81 10.59 9.033-10.59Zm-1.924 29.778h3.038L11.286 6.361h-3.26l19.83 26.59Z"
    />
  </svg>
)
export default XIcon