import { InputHTMLAttributes } from 'react'
import styled, { AnyStyledComponent } from 'styled-components'

import SearchIcon from 'src/assets/icons/search.svg?react'

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> { }

const InputContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.secondItemColor};
  border-radius: 6.25rem;
  overflow: hidden;
  width: 18.438rem;
  height: 2.625rem;
  display: grid;
  grid-template-columns: 1fr auto;
`

const Input = styled.input`
  ${({ theme }) => ({ ...theme.typography.buttonText })}
  outline: none;
  border: none;
  padding: 0 1rem;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primaryTextColor};

  ::placeholder {
    color: ${({ theme }) => theme.colors.placeholderTextColor};
    opacity: 0.7;
  }
`

const SearchIconContainer = styled.div`
  align-self: center;
  padding-right: 0.75rem;
`

const SearchIconStyled = styled(SearchIcon as AnyStyledComponent)`
  width: 1.438rem;
  height: 1.438rem;
  color: ${({ theme }) => theme.colors.placeholderTextColor};
`

const SearchInput = (props: SearchInputProps) => {
  return (
    <InputContainer>
      <Input {...props} />

      <SearchIconContainer>
        <SearchIconStyled />
      </SearchIconContainer>
    </InputContainer>
  )
}

export default SearchInput
