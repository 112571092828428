import { SVGProps } from "react"
const LinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={40}
    viewBox="0 0 38 40"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.fill || "#F9BA42"}
        d="M35.187 0H2.805C1.255 0 0 1.29 0 2.883v34.226C0 38.703 1.254 40 2.805 40h32.382C36.738 40 38 38.703 38 37.117V2.883C38 1.289 36.738 0 35.187 0ZM11.274 34.086h-5.64V14.992h5.64v19.094ZM8.454 12.39c-1.811 0-3.274-1.54-3.274-3.438 0-1.898 1.463-3.437 3.274-3.437 1.803 0 3.265 1.539 3.265 3.437 0 1.89-1.462 3.438-3.265 3.438Zm23.928 21.695h-5.634v-9.281c0-2.211-.037-5.063-2.931-5.063-2.932 0-3.377 2.414-3.377 4.906v9.438h-5.626V14.992h5.403v2.61h.074c.75-1.5 2.59-3.086 5.33-3.086 5.707 0 6.76 3.953 6.76 9.093v10.477Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h38v40H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default LinkedinIcon