import { useRef, useState } from 'react'
import styled from 'styled-components'

import { useClickOutside } from 'src/utilities/hooks'
import { Color } from 'src/utilities/theme'

const HEIGHT = 50 as const

export interface SelectOption {
  value: string
  title: string
}

interface SelectProps {
  option?: SelectOption | null
  options?: SelectOption[]
  placeholder?: string
  disabled?: boolean
  error?: boolean
  IconComponent?: React.ReactNode
  optionsVisibleCount?: number
  bgColor?: Color
  textColor?: Color
  onOptionSelect?: (option: SelectOption) => void
}

interface ButtonProps {
  error?: boolean
  hasOption?: boolean
  hasIcon?: boolean
  bgColor: Color
  textColor: Color
}

interface OptionsContainerProps {
  visible?: boolean
  optionsVisibleCount?: number
}

interface OptionContainerProps {
  active?: boolean
}

const MainContainer = styled.div`
  position: relative;
`

const Button = styled.button.attrs<ButtonProps>({
  type: 'button',
}) <ButtonProps>`
  ${({ theme }) => ({ ...theme.typography.buttonText })}
  display: block;
  width: 100%;
  height: ${HEIGHT}px;
  padding: ${({ hasIcon }) => `0 ${hasIcon ? 3.125 : 0.75}rem 0 1.25rem`};
  box-sizing: border-box;
  background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
  border-style: solid;
  border-width: 0.0625rem;
  border-color: ${({ theme, error, bgColor }) => (error ? theme.colors.errorTextColor : theme.colors[bgColor])};
  box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 2.125rem;
  color: ${({ theme, hasOption, textColor }) =>
    `${theme.colors[hasOption ? textColor : 'placeholderTextColor']}${hasOption ? '' : 'B3'}`};
  text-align: start;
  position: relative;

  :enabled {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.queries.mobile} {
    font-size: 1rem;
  }
`

const OptionsContainer = styled.div<OptionsContainerProps>`
  background-color: ${({ theme }) => theme.colors.baseItemColor};
  box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 2.125rem;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  overflow: auto;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 1;
  max-height: ${({ optionsVisibleCount }) =>
    optionsVisibleCount ? `${optionsVisibleCount * HEIGHT + HEIGHT / 2}px` : 'none'};
`

const OptionContainer = styled.button.attrs<OptionContainerProps>({
  type: 'button',
}) <OptionContainerProps>`
  ${({ theme }) => ({ ...theme.typography.buttonText })}
  display: block;
  width: 100%;
  padding: 0 1.25rem;
  box-sizing: border-box;
  background-color: ${({ active, theme }) => (active ? theme.colors.secondItemColor : 'transparent')};
  color: ${({ theme, active }) => (active ? theme.colors.primaryTextColor : theme.colors.baseItemTextColor)};
  text-align: start;
  border: 0.0625rem solid transparent;
  height: ${HEIGHT}px;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondItemColor};
    color: ${({ theme }) => theme.colors.primaryTextColor};
  }

  :enabled {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.queries.mobile} {
    font-size: 1rem;
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0.75rem;
  bottom: 0;
  display: grid;
  place-content: center;
`

const Select = ({
  option,
  options,
  placeholder,
  disabled,
  error,
  IconComponent,
  optionsVisibleCount,
  bgColor = 'baseItemColor',
  textColor = 'baseItemTextColor',
  onOptionSelect,
}: SelectProps) => {
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false)

  const mainContainerRef = useRef<HTMLDivElement>(null)

  const toggleOptions = () => {
    setOptionsVisible((prevOptionsVisible) => !prevOptionsVisible)
  }

  const handleOptionSelect = (option: SelectOption) => {
    setOptionsVisible(false)

    onOptionSelect?.(option)
  }

  useClickOutside(mainContainerRef.current, () => {
    setOptionsVisible(false)
  })

  return (
    <MainContainer ref={mainContainerRef}>
      <Button
        error={error}
        hasOption={!!option}
        hasIcon={!!IconComponent}
        disabled={disabled}
        bgColor={bgColor}
        textColor={textColor}
        onClick={toggleOptions}
      >
        {option?.title || placeholder}
        {!!IconComponent && (
          <IconContainer>
            {IconComponent}
          </IconContainer>
        )}
      </Button>

      <OptionsContainer visible={optionsVisible} optionsVisibleCount={optionsVisibleCount}>
        {options?.map((optionItem, index) => (
          <OptionContainer
            key={index}
            active={optionItem.value === option?.value}
            onClick={() => handleOptionSelect(optionItem)}
          >
            {optionItem.title}
          </OptionContainer>
        ))}
      </OptionsContainer>
    </MainContainer>
  )
}

export default Select
