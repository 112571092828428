
import { SVGProps } from "react"
const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={38}
    height={40}
    viewBox="0 0 38 40"
    fill="none"
    {...props}
  >
    <g fill={props.fill || "#F9BA42"} clipPath="url(#a)">
      <path d="M19 3.602c5.077 0 5.678.023 7.674.117 1.856.086 2.858.414 3.526.687.883.36 1.521.797 2.182 1.492.668.704 1.076 1.368 1.417 2.297.26.703.572 1.766.653 3.711.09 2.11.112 2.742.112 8.078 0 5.344-.023 5.977-.112 8.078-.081 1.954-.393 3.008-.653 3.711-.341.93-.757 1.602-1.417 2.297-.668.703-1.3 1.133-2.182 1.492-.668.274-1.678.602-3.526.688-2.004.094-2.605.117-7.674.117-5.077 0-5.678-.023-7.674-.117-1.856-.086-2.858-.414-3.526-.688a5.856 5.856 0 0 1-2.182-1.492c-.668-.703-1.076-1.367-1.417-2.297-.26-.703-.572-1.765-.653-3.71-.09-2.11-.112-2.743-.112-8.079 0-5.343.023-5.976.112-8.078.081-1.953.393-3.008.653-3.71.341-.93.757-1.602 1.417-2.298.668-.703 1.3-1.132 2.182-1.492.668-.273 1.678-.601 3.526-.687 1.996-.094 2.597-.117 7.674-.117ZM19 0c-5.158 0-5.804.023-7.83.117-2.019.094-3.407.438-4.61.93a9.24 9.24 0 0 0-3.369 2.312C2.131 4.47 1.484 5.586.995 6.9.527 8.171.2 9.624.11 11.75.022 13.89 0 14.57 0 20s.022 6.11.111 8.242c.09 2.125.416 3.586.884 4.852.49 1.32 1.135 2.437 2.196 3.547 1.054 1.109 2.116 1.797 3.363 2.304 1.21.492 2.59.836 4.608.93 2.027.094 2.672.117 7.83.117 5.159 0 5.805-.023 7.83-.117 2.02-.094 3.407-.438 4.61-.93 1.247-.508 2.308-1.195 3.362-2.304 1.054-1.11 1.707-2.227 2.19-3.54.467-1.273.793-2.726.882-4.851.09-2.133.112-2.813.112-8.242 0-5.43-.023-6.11-.112-8.242-.089-2.125-.415-3.586-.883-4.852-.467-1.328-1.113-2.445-2.174-3.555-1.054-1.109-2.116-1.796-3.362-2.304-1.21-.492-2.59-.836-4.61-.93C24.804.023 24.159 0 19 0Z" />
      <path d="M19 9.727c-5.388 0-9.76 4.601-9.76 10.273S13.612 30.273 19 30.273 28.76 25.672 28.76 20 24.388 9.727 19 9.727Zm0 16.937c-3.496 0-6.33-2.984-6.33-6.664 0-3.68 2.834-6.664 6.33-6.664S25.33 16.32 25.33 20c0 3.68-2.834 6.664-6.33 6.664ZM31.424 9.32c0 1.328-1.024 2.399-2.278 2.399-1.262 0-2.279-1.079-2.279-2.399 0-1.328 1.024-2.398 2.279-2.398 1.254 0 2.278 1.078 2.278 2.398Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h38v40H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default InstagramIcon