import styled, { AnyStyledComponent } from 'styled-components'
import { useSelector } from 'react-redux'

import IconButton from './IconButton'
import { RootState } from 'src/utilities/store'
import Text from './Text'
import { selectHasCustomPages } from 'src/models/website'
import { useHeaderTitle } from 'src/utilities/hooks'
import { QrTitleDisplayType } from 'src/types/api'

interface HeaderContentProps {
  onRestaurantClick?: () => void
  onMenuClick?: () => void
}

const HeaderContentContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.sizes.iconButtonSize}px 1fr ${({ theme }) => theme.sizes.iconButtonSize}px;
  column-gap: 0.5rem;
  align-items: center;
  height: ${({ theme }) => theme.sizes.headerHeight}px;
  background-color: ${({ theme }) => theme.colors.baseItemColor};
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.5);
  padding: 0 0.75rem;
`

const HeaderTitle = styled(Text as unknown as AnyStyledComponent).attrs({
  type: 'productBoxName',
  align: 'center',
  style: {
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  color: 'baseItemTextColor',
})``

const LocationIconButton = styled(IconButton as unknown as AnyStyledComponent).attrs({
  name: 'location',
  variant: 'gray',
})``

const HeaderContent = ({ onMenuClick, onRestaurantClick }: HeaderContentProps) => {
  const group = useSelector((state: RootState) => state.group.group)

  const titleDisplayType = useSelector((state: RootState) => state.profile.user?.qrTitleDisplayType)

  const hasCustomPages = useSelector(selectHasCustomPages)

  const headerTitle = useHeaderTitle()

  return (
    <HeaderContentContainer>
      <div>{!!group && titleDisplayType !== QrTitleDisplayType.None && <LocationIconButton onClick={onRestaurantClick} />}</div>

      <HeaderTitle>{headerTitle}</HeaderTitle>

      <div>{hasCustomPages && <IconButton name="menu" onClick={onMenuClick} />}</div>
    </HeaderContentContainer>
  )
}

export default HeaderContent
