import styled, { AnyStyledComponent } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Footer from 'src/components/Footer'
import { selectCart, selectCartTotalPrice, selectIsCartEmpty } from 'src/models/cart'
import CartIcon from 'src/assets/icons/cart.svg?react'
import Modal from 'src/components/Modal'
import SearchInput from './components/SearchInput'
import TextButton from 'src/components/TextButton'
import { selectProductsWithCartItems, ProductWithCartItem } from 'src/models/cart'
import ProductsList from 'src/components/ProductsList'
import { OrderType, ProductContract } from 'src/types/api'
import { Dispatch, RootState } from 'src/utilities/store'
import { useMediaQuery, useWIndowSizes } from 'src/utilities/hooks'
import NewModal from 'src/components/NewModal'
import ProductsListItem from 'src/components/ProductsListItem'
import {
  findDuplicatedCartItem,
  getCartItemWithPreselectedOptions,
  getCartTotalPrice,
  instantAddToCart,
  setBodyOverflow,
} from 'src/utilities/functions'
import { selectAcceptsAllOrderTypes } from 'src/models/profile'

interface LocationState {
  backgroundLocation: Location
}

interface MainContainerProps {
  height: number
}

const MainContainer = styled.div<MainContainerProps>`
  height: ${({ height }) => height}px;
  display: grid;
  grid-template-rows: 1fr auto;
`

const ContentContainer = styled.div`
  overflow-y: auto;
  overscroll-behavior: none;
`

const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  align-items: center;
  padding: 1rem 0.75rem;
  box-shadow: 0px -2px 14px rgba(0, 0, 0, 0.5);
  background-color: ${({ theme }) => theme.colors.baseItemColor};
  position: sticky;
  top: 0;
  z-index: 1;
`

const ProductsListContainer = styled.div`
  padding: 0.375rem 0 calc(0.375rem + ${({ theme }) => theme.sizes.cartButtonSize / 2}px) 0;
`

const CartIconStyled = styled(CartIcon as AnyStyledComponent)`
   width: 1.625rem;
  height: 1.625rem;
  color: ${({ theme }) => theme.colors.primaryTextColor};
`

const ProductListItemsContainer = styled.div`
  padding: 1.25rem calc(${({ theme }) => theme.sizes.iconButtonSize}px + 1.25rem) 1.25rem 1.25rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
`

const Search = () => {
  const [filteredProducts, setFilteredProducts] = useState<ProductWithCartItem[]>([])

  const cartTotalPrice = useSelector(selectCartTotalPrice)
  const productsWithCartItems = useSelector(selectProductsWithCartItems)
  const cart = useSelector(selectCart)
  const searchValue = useSelector((state: RootState) => state.search.searchValue)
  const user = useSelector((state: RootState) => state.profile.user)
  const acceptsAllOrderTypes = useSelector(selectAcceptsAllOrderTypes)
  const isCartEmpty = useSelector(selectIsCartEmpty)

  const dispatch = useDispatch<Dispatch>()

  const { t } = useTranslation()

  const sizes = useWIndowSizes()

  const { isMobile } = useMediaQuery()

  const contentContainerRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()
  const params = useParams<{ tabletId: string }>()
  const location = useLocation()
  const locationState = location.state as LocationState | null

  const filterProducts = useCallback(() => {
    const filtered = productsWithCartItems.filter((productWithCartItem) =>
      productWithCartItem.product.title!.toLowerCase().includes(searchValue.toLowerCase()),
    )
    setFilteredProducts(filtered)
  }, [productsWithCartItems, searchValue])

  const closeSearch = useCallback(() => {
    dispatch.search.setSearchValue('')

    navigate(-1)
  }, [dispatch.search, navigate])

  const calculateOrderPrice = useCallback(async () => {
    if (isCartEmpty) {
      return
    }

    try {
      await dispatch.orders.calculateOrderPrice({ userVisiblePrice: cartTotalPrice })
    } catch (error) {
      console.error(error)
    }
  }, [cartTotalPrice, dispatch.orders, isCartEmpty])

  const navigateToCheckout = useCallback(() => {
    if (isMobile) {
      navigate(`/${params.tabletId}/checkout`)
      return
    }

    navigate(`/${params.tabletId}/checkout`, { state: { backgroundLocation: locationState?.backgroundLocation } })
  }, [isMobile, locationState?.backgroundLocation, navigate, params.tabletId])

  const navigateToProduct = useCallback(
    (data: { productId: string; cartId?: string }) => {
      const cartId = data.cartId
      const state = { backgroundLocation: locationState?.backgroundLocation }
      navigate(`/${params.tabletId}/${data.productId}`, { state: cartId ? { ...state, cartId } : state })
    },
    [locationState?.backgroundLocation, navigate, params.tabletId],
  )

  const handleAdd = useCallback(
    (product: ProductContract) => {
      if (instantAddToCart(product.optionSets!)) {
        const cartItem = getCartItemWithPreselectedOptions(product)

        const foundDuplicatedCartItem = findDuplicatedCartItem(cart.items, cartItem)
        if (foundDuplicatedCartItem) {
          dispatch.cart.increaseProductCount(foundDuplicatedCartItem.id)

          return
        }

        dispatch.cart.addToCart(cartItem)

        return
      }

      navigateToProduct({ productId: product.id! })
    },
    [cart.items, dispatch.cart, navigateToProduct],
  )

  const handleProductIncrease = useCallback(
    (id: string) => {
      dispatch.cart.increaseProductCount(id)
    },
    [dispatch.cart],
  )

  const handleProductDecrease = useCallback(
    (id: string) => {
      if (cart.items[id].count > 1) {
        dispatch.cart.decreaseProductCount(id)
      } else {
        dispatch.cart.removeFromCart(id)
      }
    },
    [cart.items, dispatch.cart],
  )

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch.search.setSearchValue(e.target.value)
    },
    [dispatch.search],
  )

  const handleContentClick = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      if (contentContainerRef.current !== e.target) {
        return
      }

      closeSearch()
    },
    [closeSearch],
  )

  const handleSearchValueChange = (searchValue: string) => {
    dispatch.search.setSearchValue(searchValue)
  }

  const handleCartClick = useCallback(() => {
    if (acceptsAllOrderTypes) {
      navigateToCheckout()
      return
    }

    if (user?.isAcceptingTakeAwayOrders) {
      dispatch.cart.setOrderType(OrderType.TakeAway)
    }

    if (user?.isAcceptingTableOrders) {
      dispatch.cart.setOrderType(OrderType.Table)
    }

    navigateToCheckout()
  }, [
    acceptsAllOrderTypes,
    dispatch.cart,
    navigateToCheckout,
    user?.isAcceptingTableOrders,
    user?.isAcceptingTakeAwayOrders,
  ])

  useEffect(() => {
    calculateOrderPrice()
  }, [calculateOrderPrice])

  useEffect(() => {
    filterProducts()
  }, [filterProducts])

  useEffect(() => {
    setBodyOverflow('hidden')

    return () => setBodyOverflow('visible')
  }, [])

  if (isMobile) {
    return (
      <Modal isOpen={true}>
        <MainContainer height={sizes.height}>
          <ContentContainer ref={contentContainerRef} onClick={handleContentClick}>
            <HeaderContainer>
              <SearchInput
                value={searchValue}
                placeholder={t('pages.search.placeholder')}
                autoFocus
                onChange={handleChange}
              />
              <TextButton onClick={closeSearch}>{t('common.doneButtonText')}</TextButton>
            </HeaderContainer>
            <ProductsListContainer>
              <ProductsList
                scrollElement={contentContainerRef.current!}
                products={filteredProducts}
                onAdd={handleAdd}
                onIncrease={handleProductIncrease}
                onDecrease={handleProductDecrease}
                onItemClick={navigateToProduct}
              />
            </ProductsListContainer>
          </ContentContainer>
          <Footer
            cartSum={getCartTotalPrice(cart)}
            intercomEnabled={user?.enableSupportChat}
            CartContentComponent={<CartIconStyled />}
            onCartClick={handleCartClick}
            onSearchClick={closeSearch}
          />
        </MainContainer>
      </Modal>
    )
  }

  return (
    <NewModal
      isOpen={true}
      showCloseButton
      onRequestClose={closeSearch}
      width="61.375rem"
      height="46.25rem"
      alignment="bottom"
      scrollPaddingX="1.8125rem"
      scrollPaddingY="4.125rem"
      FooterComponent={
        <Footer
          autoFocus
          searchValue={searchValue}
          cartItemsCount={Object.keys(cart.items).length}
          cartSum={cartTotalPrice}
          intercomEnabled={user?.enableSupportChat}
          CartContentComponent={<CartIconStyled />}
          onCartClick={handleCartClick}
          onSearchClick={closeSearch}
          onSearchValueChange={handleSearchValueChange}
        />
      }
    >
      <ProductListItemsContainer>
        {filteredProducts.map(({ product, cartItem }, index) => (
          <ProductsListItem
            key={index}
            product={product}
            cartItem={cartItem}
            onAdd={handleAdd}
            onIncrease={handleProductIncrease}
            onDecrease={handleProductDecrease}
            onItemClick={navigateToProduct}
          />
        ))}
      </ProductListItemsContainer>
    </NewModal>
  )
}

export default Search
