import styled, { AnyStyledComponent } from 'styled-components'

import RevolutIcon from 'src/assets/icons/revolut.svg?react'
import SwedbankIcon from 'src/assets/icons/swedbank.svg?react'
import SebIcon from 'src/assets/icons/seb.svg?react'

const MainContainer = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;
  pointer-events: none;
`

const RevolutIconStyled = styled(RevolutIcon as AnyStyledComponent)`
  height: 1.55rem;
  width: auto;
  color: ${({ theme }) => theme.colors.primaryTextColor};
`

const SwedbankIconStyled = styled(SwedbankIcon as AnyStyledComponent)`
  height: 1.55rem;
  width: auto;
  color: ${({ theme }) => theme.colors.primaryTextColor};
`

const SebIconStyled = styled(SebIcon as AnyStyledComponent)`
  height: 1.25rem;
  width: auto;
  color: ${({ theme }) => theme.colors.primaryTextColor};
`

const BankIcons = () => {
  return (
    <MainContainer>
      <RevolutIconStyled />

      <SebIconStyled />

      <SwedbankIconStyled />
    </MainContainer>
  )
}

export default BankIcons
