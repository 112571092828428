import { useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import AddInfoCard from 'src/components/AddInfoCard'
import { Dispatch, RootState } from 'src/utilities/store'
import { ErrorContract } from 'src/types/api'
import ErrorModal from 'src/components/ErrorModal'
import { selectCartTotalPrice } from 'src/models/cart'
import { handleError } from 'src/utilities/functions'
import { useMediaQuery } from 'src/utilities/hooks'
import NewModal from 'src/components/NewModal'

interface LocationState {
  backgroundLocation?: Location
}

const Discount = () => {
  const [errorMsg, setErrorMsg] = useState<string>()

  const cartTotalPrice = useSelector(selectCartTotalPrice)

  const calculateOrderPriceLoading = useSelector((state: RootState) => state.loading.effects.orders.calculateOrderPrice)
  const checkLoyaltyCodeLoading = useSelector((state: RootState) => state.loading.effects.orders.checkLoyaltyCode)

  const dispatch = useDispatch<Dispatch>()

  const navigate = useNavigate()
  const params = useParams<{ tabletId: string }>()
  const location = useLocation()
  const locationState = location.state as LocationState | null

  const { t } = useTranslation()

  const { isMobile } = useMediaQuery()

  const loading = useMemo(() => {
    return calculateOrderPriceLoading || checkLoyaltyCodeLoading
  }, [calculateOrderPriceLoading, checkLoyaltyCodeLoading])

  const navigateBack = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const navigateToCheckout = useCallback(() => {
    if (isMobile) {
      navigate(`/${params.tabletId}/checkout`, { replace: true, state: { fromDiscount: true } })
      return
    }

    navigate(`/${params.tabletId}/checkout`, {
      replace: true,
      state: { backgroundLocation: locationState?.backgroundLocation, fromDiscount: true },
    })
  }, [isMobile, locationState?.backgroundLocation, navigate, params.tabletId])

  const clearErrorMsg = useCallback(() => {
    setErrorMsg(undefined)
  }, [])

  const calculateOrderPrice = useCallback(
    async (data?: { promoCode?: string; loyaltyCode?: string }) => {
      try {
        return await dispatch.orders.calculateOrderPrice({
          userVisiblePrice: cartTotalPrice,
          promoCode: data?.promoCode,
          loyaltyCode: data?.loyaltyCode,
        })
      } catch (error) {
        const axiosError = error as AxiosError<ErrorContract>;
        if (axiosError?.response?.data?.internalErrorCode === 15) {
          console.log('promo code not found');
          return;
        }

        throw error
      }

    },
    [cartTotalPrice, dispatch.orders],
  )

  const handleDoneClick = useCallback(
    async (value: string) => {
      try {
        const orderPriceData = await calculateOrderPrice({ promoCode: value })

        if (orderPriceData?.promoCode) {
          dispatch.cart.updatePromoCode(orderPriceData.promoCode)

          navigateToCheckout()

          return
        }

        const loyaltyCodeData = await dispatch.orders.checkLoyaltyCode({
          loyaltyCode: value,
          userVisiblePrice: cartTotalPrice,
        })

        await calculateOrderPrice({ loyaltyCode: loyaltyCodeData.loyaltyCode! })

        navigateToCheckout()
      } catch (error) {

        const errorObj = handleError(error as AxiosError<ErrorContract>)
        if (!errorObj?.errorMsg) {
          return
        }

        setErrorMsg(errorObj.errorMsg)
      }
    },
    [calculateOrderPrice, cartTotalPrice, dispatch.cart, dispatch.orders, navigateToCheckout],
  )

  return (
    <>
      <NewModal isOpen={true} width={isMobile ? '100%' : '30rem'}>
        <AddInfoCard
          disabled={loading}
          title={t('pages.discount.title')}
          placeholder={t('pages.discount.placeholder')}
          autoFocus
          inputType="text"
          onBackClick={navigateBack}
          onDoneClick={handleDoneClick}
        />
      </NewModal>
      <ErrorModal errorMsg={errorMsg} onBackClick={clearErrorMsg} onRetryClick={clearErrorMsg} />
    </>
  )
}

export default Discount
