import { ComponentPropsWithRef, forwardRef, useEffect } from 'react'
import styled from 'styled-components'
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { Locale } from 'date-fns/locale'
import { lt } from 'date-fns/locale/lt'
import { ru } from 'date-fns/locale/ru'
import { fr } from 'date-fns/locale/fr'
import { tr } from 'date-fns/locale/tr'

import { LanguageCode } from 'src/types/api'

const LOCALE: { [key in LanguageCode]?: Locale } = {
  lt,
  ru,
  fr,
  tr,
}

interface DateInputProps extends ReactDatePickerProps {
  error?: boolean
  IconComponent?: React.ReactNode
}

interface CustomInputProps extends ComponentPropsWithRef<'button'> {
  error?: boolean
  IconComponent?: React.ReactNode
}

interface ButtonProps {
  error?: boolean
  hasValue?: boolean
  hasIcon?: boolean
}

const Container = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.secondItemColor};
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${({ theme }) => theme.colors.secondItemColor};
    border-radius: 0.3rem;
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.secondItemColor};
  }

  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.colors.primaryTextColor};
  }

  .react-datepicker__day-name,
  .react-datepicker__current-month,
  .react-datepicker-time__header {
    color: ${({ theme }) => theme.colors.primaryTextColor};
  }

  .react-datepicker__header.react-datepicker__header--time {
    height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day--keyboard-selected.react-datepicker__day--today {
    background-color: #f0f0f0;
  }
`

const Button = styled.button.attrs<ButtonProps>({
  type: 'button',
}) <ButtonProps>`
  ${({ theme }) => ({ ...theme.typography.buttonText })}
  display: block;
  width: 100%;
  height: 3.125rem;
  padding: ${({ hasIcon }) => `0 ${hasIcon ? 3.125 : 0.75}rem 0 1.25rem`};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.baseItemColor};
  border-style: solid;
  border-width: 0.0625rem;
  border-color: ${({ theme, error }) => (error ? theme.colors.errorTextColor : theme.colors.baseItemColor)};
  box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 2.125rem;
  color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.baseItemTextColor : `${theme.colors.placeholderTextColor}B3`};
  text-align: start;
  position: relative;

  :enabled {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.queries.mobile} {
    font-size: 1rem;
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0.75rem;
  bottom: 0;
  display: grid;
  place-content: center;
`

const CustomInput = forwardRef<HTMLButtonElement, CustomInputProps>(
  ({ value, placeholder, error, IconComponent, ...props }, ref) => {
    return (
      <Button ref={ref} error={error} hasValue={!!value} hasIcon={!!IconComponent} {...props}>
        {value || placeholder}
        {!!IconComponent && (
          <IconContainer>
            {IconComponent}
          </IconContainer>
        )}
      </Button>
    )
  },
)

const DateInput = forwardRef<DatePicker, DateInputProps>(({ error, IconComponent, ...props }, ref) => {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const locale = LOCALE[i18n.language as LanguageCode]
    if (!locale) {
      return
    }

    registerLocale(i18n.language, locale)
  }, [i18n.language])

  return (
    <Container>
      {/* @ts-ignore */}
      <DatePicker
        ref={ref}
        locale={i18n.language}
        showTimeSelect
        timeCaption={t('pages.dynamicPage.components.dateInput.timeLabel')}
        dateFormat="Pp"
        customInput={<CustomInput error={error} IconComponent={IconComponent} />}
        popperPlacement="bottom-start"
        timeIntervals={15}
        {...props}
      />
    </Container>
  )
})

export default DateInput
