import { SVGProps } from "react"

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 38}
    height={props.height || 40}
    fill="none"
    viewBox="0 0 38 40"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.fill || '#F9BA42'}
        d="M19 0C8.507 0 0 8.954 0 20c0 9.38 6.135 17.25 14.41 19.411V26.112h-3.917V20h3.917v-2.634c0-6.807 2.927-9.962 9.276-9.962 1.204 0 3.281.249 4.13.497v5.54c-.448-.05-1.227-.075-2.194-.075-3.115 0-4.32 1.243-4.32 4.472V20h6.207l-1.066 6.112h-5.14v13.742C30.71 38.658 38 30.226 38 20 38 8.954 29.493 0 19 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h38v40H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default FacebookIcon
