import styled, { AnyStyledComponent } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Text from 'src/components/Text'
import PrimaryButton from 'src/components/PrimaryButton'
import NewModal from 'src/components/NewModal'
import { useMediaQuery } from 'src/utilities/hooks'
import AllergensInfoIcon from 'src/assets/icons/allergens-info.svg?react'

interface AllergensModalProps {
  isOpen?: boolean
  onCloseClick?: () => void
  onMoreClick?: () => void
}

const ContentContainer = styled.div`
  padding: 2.5rem 1.5rem;
  display: grid;
  row-gap: 1rem;
  justify-items: center;

  @media ${({ theme }) => theme.queries.mobile} {
    padding: 1.875rem 0.75rem;
  }
`

const AllergensInfoIconStyled = styled(AllergensInfoIcon as AnyStyledComponent)`
  height: 4.0625rem;
  width: auto;
  color: ${({ theme }) => theme.colors.tertiaryTextColor};
`

const FooterContainer = styled.div`
  padding: 0.625rem 1.5rem;
  display: grid;
  justify-items: center;
  filter: drop-shadow(0px -2px 14px rgba(0, 0, 0, 0.07));
  background-color: ${({ theme }) => theme.colors.baseItemColor};
  border-radius: 0 0 1.25rem 1.25rem;
`

const AllergensModal = ({ isOpen, onCloseClick, onMoreClick }: AllergensModalProps) => {
  const { t } = useTranslation()

  const { isMobile } = useMediaQuery()

  return (
    <NewModal isOpen={!!isOpen} width={isMobile ? '100%' : '30rem'} showCloseButton onRequestClose={onCloseClick}>
      <ContentContainer>
        <AllergensInfoIconStyled />

        <Text type={isMobile ? 'checkoutPageTitle' : 'productScreenTitleBold'} align="center" color="baseItemTextColor">
          {t('pages.product.components.allergensModal.title')}
        </Text>

        <Text
          type={isMobile ? 'checkoutPageOptionDescription' : 'productScreenDescription'}
          align="center"
          color="baseItemTextColor"
        >
          {t('pages.product.components.allergensModal.description')}
        </Text>
      </ContentContainer>

      <FooterContainer>
        <PrimaryButton width="13.5rem" onClick={onMoreClick}>
          {t('pages.product.components.allergensModal.moreButtonText')}
        </PrimaryButton>
      </FooterContainer>
    </NewModal>
  )
}

export default AllergensModal
