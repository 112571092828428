import { CSSProperties, memo, ReactNode, RefObject, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { sizes } from 'src/utilities/theme'
import Carousel from 'src/components/Carousel'

export const HEADER_HEIGHT = 54 as const

interface HeaderProps {
  imgsUrl?: string[]
  imgHeight: number
  BgContentComponent: ReactNode
  HeaderContentComponent: ReactNode
  scrollRef?: RefObject<HTMLDivElement>
  bgImgStyle?: CSSProperties
  TopComponent?: ReactNode
}

interface BgImgProps {
  height?: number
  url?: string
}

interface BgContentContainerProps {
  visible: boolean
}

interface HeaderContainerProps {
  marginTop: number
  opacity: number
}

interface CarouselContainerProps {
  height?: number
  opacity?: number
}

interface BgContainerProps {
  headerVisible?: boolean
}

const MainContainer = styled.div`
  position: relative;
`

const BgContainer = styled.div<BgContainerProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  ${({ headerVisible }) => !headerVisible && `z-index: 1;`}
`

const BgImg = styled.div<BgImgProps>`
  background-image: url(${({ url }) => url});
  background-size: cover;
  background-position: center;
  height: ${({ height = 0 }) => height}px;
`

const BgContentContainer = styled.div<BgContentContainerProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

const HeaderContainer = styled.div<HeaderContainerProps>`
  height: ${HEADER_HEIGHT}px;
  margin-top: ${({ marginTop }) => marginTop}px;
  transition: 0.1s opacity;
  opacity: ${({ opacity }) => opacity};
`

const CarouselContainer = styled.div<CarouselContainerProps>`
  height: ${({ height = 0 }) => height}px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transition: 0.1s opacity;
  opacity: ${({ opacity = 0 }) => opacity};
`

const Header = ({ imgsUrl = [], TopComponent, ...props }: HeaderProps) => {
  const [bgImgOpacity, setBgImgOpacity] = useState<number>(1)
  const [headerOpacity, setHeaderOpacity] = useState<number>(0)

  const marginTop = useMemo(() => props.imgHeight - sizes.headerHeight, [props.imgHeight])

  const getBgImgOpacity = useCallback(() => {
    const scrollY = props.scrollRef?.current ? props.scrollRef.current.scrollTop : window.scrollY

    const opacity = 1 - (scrollY * 100) / marginTop / 100

    if (opacity < 0) {
      return 0
    }

    if (opacity > 1) {
      return 1
    }

    return Math.abs(opacity)
  }, [marginTop, props.scrollRef])

  const getHeaderOpacity = useCallback(() => {
    const scrollY = props.scrollRef?.current ? props.scrollRef.current.scrollTop : window.scrollY

    return scrollY >= Math.floor(marginTop) ? 1 : 0
  }, [marginTop, props.scrollRef])

  const handleScroll = useCallback(() => {
    setBgImgOpacity(getBgImgOpacity())

    setHeaderOpacity(getHeaderOpacity())
  }, [getBgImgOpacity, getHeaderOpacity])

  useEffect(() => {
    if (props.scrollRef?.current) {
      props.scrollRef.current.addEventListener('scroll', handleScroll)
    } else {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (props.scrollRef?.current) {
        props.scrollRef.current.removeEventListener('scroll', handleScroll)
      } else {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll, props.scrollRef])

  return (
    <MainContainer>
      <BgContainer headerVisible={!!headerOpacity}>
        <CarouselContainer height={props.imgHeight} opacity={bgImgOpacity}>
          {!!TopComponent && TopComponent}

          <Carousel showIndicators={imgsUrl.length > 1}>
            {imgsUrl.map((imgUrl, index) => (
              <BgImg key={index} url={imgUrl} height={props.imgHeight} style={props.bgImgStyle} />
            ))}
          </Carousel>
        </CarouselContainer>

        <BgContentContainer visible={headerOpacity === 0}>{props.BgContentComponent}</BgContentContainer>
      </BgContainer>

      <HeaderContainer marginTop={marginTop} opacity={headerOpacity}>
        {props.HeaderContentComponent}
      </HeaderContainer>
    </MainContainer>
  )
}

export default memo(Header)
